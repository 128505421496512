<template>
  <div class="navbar-container d-flex content align-items-center justify-content-between">

    <!-- Left Col -->
    <div class="bookmark-wrapper align-items-center d-none d-flex" />

    <div class="nav navbar-nav navbar-center align-items-center d-flex">
      <p class="fontSelect color-white">
        {{ selectedProductsCount }} {{ selectedProductsCount > 1 ? 'Products selected': 'Product selected' }}
      </p>
    </div>

    <!-- Right Col -->
    <b-navbar-nav class="nav align-items-center">
      <template>
        <b-button
          v-if="hasDirectRetailers"
          class="mr-1"
          variant="custom-secondary"
          @click="addToOrder"
        >
          Add to Order
        </b-button>
        <b-button
          v-if="isBrand && isShop"
          class="mr-1"
          variant="custom-secondary"
          @click="selectOrCreateWishlist"
        >
          Add to Selection
        </b-button>
        <b-button
          v-if="isBrand && !isShop"
          class="mr-1"
          variant="custom-secondary"
          :disabled="isRemovingFromWishlistProducts"
          @click="removeAllFromWishList"
        >
          <b-spinner
            v-if="isRemovingFromWishlistProducts"
            small
          />
          <span v-else>
            Remove from Selection
          </span>
        </b-button>
        <b-dropdown
          class="bi bi-three-dots-vertical select-action ml-0 mr-1"
          right
          variant="outline-custom-light"
        >
          <template #button-content>
            <span> {{ isRetailer ? 'Select/Deselect All' : 'Other actions' }} </span>
            <feather-icon icon="MoreVerticalIcon" />
          </template>
          <b-dropdown-item
            v-if="isBrand && isShop"
            @click="downloadSelectedProducts"
          >
            Download products
          </b-dropdown-item>
          <b-dropdown-item @click="()=>selectAll(true)">
            Select All
          </b-dropdown-item>
          <b-dropdown-item @click="()=>selectAll(false)">
            Deselect All
          </b-dropdown-item>
        </b-dropdown>
        <b-dropdown
          v-if="!isBrand"
          class="bi bi-three-dots-vertical select-action"
          right
          variant="outline-custom-light"
          :disabled="isRemovingFromWishlistProducts"
        >
          <template #button-content>
            <b-spinner
              v-if="isRemovingFromWishlistProducts"
              small
            />
            <template v-else>
              <span> Other actions </span>
              <feather-icon icon="MoreVerticalIcon" />
            </template>
          </template>
          <template v-if="$store.state['shop'].selectType==='SHOP'">
            <b-dropdown-item
              v-if="isOrderEnableInShop && isRetailer"
              @click="addToCartFromShop"
            >
              Add to cart
            </b-dropdown-item>
            <b-dropdown-item @click="downloadSelectedProducts">
              Download products
            </b-dropdown-item>
            <b-dropdown-item
              v-if="isRetailer"
              @click="selectOrCreateWishlist"
            >
              Move to Wishlist
            </b-dropdown-item>
            <b-dropdown-item
              v-if="isRetailer"
              @click="removeWishlistFromShop"
            >
              Remove From Wishlist
            </b-dropdown-item>
          </template>
          <template v-else>
            <b-dropdown-item
              v-if="isOrderEnableInWishlist && isRetailer"
              @click="moveAllToCartFromWishlist"
            >
              Add to cart
            </b-dropdown-item>
            <b-dropdown-item
              @click="downloadSelectedProductsFromWishlist"
            >
              Download Products
            </b-dropdown-item>
            <b-dropdown-item @click="removeAllFromWishList">
              Remove From {{ getWishlistTitle(isRetailer) }}
            </b-dropdown-item>
          </template>
        </b-dropdown>
      </template>
      <feather-icon
        icon="XIcon"
        size="25"
        class="cursor-pointer"
        @click="close"
      />
    </b-navbar-nav>
  </div>
</template>

<script>
import {
  BNavbarNav, BDropdown, BDropdownItem, BButton,
BSpinner,
} from 'bootstrap-vue'
import FeatherIcon from '@/@core/components/feather-icon/FeatherIcon.vue'
import constants from '@/constants'
import { getWishlistTitle } from '@/@core/utils/utils'
import UserRoleMixin from '@/views/apps/UserRoleMixin.vue'
import { UPDATE_IS_SELECTED_ALL_PRODUCTS } from '@/store/modules/shop.module'
import { constants as c } from '@kingpin-global/kingpin-utils-frontend'

const { ROLES } = c

const { CONNECTION_REQUEST_STATUS } = constants
export default {
  components: {
    BDropdown,
    BDropdownItem,
    BButton,
    // Navbar Components
    BNavbarNav,
    FeatherIcon,
    BSpinner,
  },
  mixins: [UserRoleMixin],
  data() {
    return {
      getWishlistTitle,
      isRemovingFromWishlistProducts: false,
    }
  },
  computed: {
    isOrderEnableInShop() {
      if (this.$root.$refs.shop.isPremium) {
        const enableCheck = this.$root.$refs.shop.connectionRequestStatus === CONNECTION_REQUEST_STATUS.APPROVED
        return enableCheck
      }
      return true
    },
    isOrderEnableInWishlist() {
      if (this.$root.$refs.wishlist.isPremium) {
        const enableCheck = this.$root.$refs.wishlist.requestStatus === CONNECTION_REQUEST_STATUS.APPROVED
        return enableCheck
      }
      return true
    },
    isShop() {
      return this.$store.state.shop.selectType === 'SHOP'
    },
    hasDirectRetailers() {
      const userAssociation = this.$store.getters.getCurrentAssociation
      return this.isShop && (this.$root.$refs.shop.hasDirectRetailers || userAssociation?.entityType === ROLES.BRAND)
    },
    selectedProductsCount() {
      const shopState = this.$store.state.shop
      return (shopState.isSelectedAllProducts && shopState.totalProductsCount) || shopState.selectCount
    },
  },
  methods: {
    moveAllToCartFromWishlist() {
      this.$root.$refs.wishlist.moveAllToCart()
    },
    async removeAllFromWishList() {
      this.isRemovingFromWishlistProducts = true
      await this.$root.$refs.wishlist.removeAllFromWishList()
      this.isRemovingFromWishlistProducts = false
    },
    moveAllToWishListFromShop() {
      this.$root.$refs.shop.moveAllToWishList()
    },
    selectOrCreateWishlist() {
      this.$root.$refs.shop.selectOrCreateWishlist()
    },
    removeWishlistFromShop() {
      this.$root.$refs.shop.removeAllFromWishList()
    },
    addToCartFromShop() {
      this.$root.$refs.shop.moveAllToCart()
    },
    addToOrder() {
      this.$root.$refs.shop.addToOrder()
    },
    downloadSelectedProductsFromWishlist() {
      this.$root.$refs.wishlist.downloadSelectedWishlistProducts()
    },
    downloadSelectedProducts() {
      this.$root.$refs.shop.downloadSelectedProducts()
    },
    selectAll(select) {
    if (this.$store.state.shop.selectType === 'SHOP') {
      this.$root.$refs.shop.selectAll(select)
    } else {
       this.$root.$refs.wishlist.selectAll(select)
      }
    },
    close() {
      if (this.$store.state.shop.selectType === 'SHOP') this.$root.$refs.shop.unSelectAll()
      else { this.$root.$refs.wishlist.selectAll(false) }
      this.$store.commit(UPDATE_IS_SELECTED_ALL_PRODUCTS, false)
    },

  },
}
</script>
<style lang="scss" >
@import "src/@core/scss/base/bootstrap-extended/_variables.scss";

.fontSelect{
    color: $black;
    font-weight: $font-weight-bold;
    font-size: $h2-font-size;
    margin-bottom: 0;
}
.select-action{
  margin-top: 4px;
  margin: 0 25px;
}
@media (max-width: 576px) {
  .fontSelect {
    font-size: $h5-font-size;
    margin-right: 0;
  }
  .select-span{
    margin-right: 0;
  }
  .select-action{
    margin: 0;
  }
}
</style>
